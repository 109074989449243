import * as React from "react";
import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import * as styles from "./about-us.module.css";

const AboutUs: FunctionComponent = () => {
  return (
    <Layout>
      <div className={styles.aboutUsDiv}>
        <div className={styles.aboutUsIntroDiv}>
          <div className={styles.aboutUsDiv1}>
            <div className={styles.aboutUsTextDiv}>
              <div className={styles.titleDiv}>
                <div className={styles.displayDiv}>About Us</div>
              </div>
              <div className={styles.heroTextDiv}>
                <div className={styles.pDiv}>
                  <div className={styles.captionDiv}>
                    We are a wholesale distributor of clinical instruments and
                    medical products for healthcare businesses such as clinics,
                    hospitals and private practices. We focus on affordability
                    and quality, so you get the maximum return on your
                    investment.
                  </div>
                </div>
                <div className={styles.pDiv}>
                  <div className={styles.captionDiv}>
                    <p className={styles.blankLineP}>
                      With over 120 years of combined experience in the medical
                      field, our highly efficient team is committed to
                      maintaining lasting relationships with all customers and
                      assisting them with a state of art solutions for their
                      healthcare business.
                    </p>
                    <p className={styles.blankLineP}>&nbsp;</p>
                    <p className={styles.weStandOut}>
                      We stand out because of:
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.featuresDiv}>
                <div className={styles.featureDiv}>
                  <div className={styles.titleDiv1}>
                    <img
                      className={styles.iconFilled}
                      alt=""
                      src="/icon--filled10.svg"
                    />
                    <b className={styles.captionDiv}>1</b>
                  </div>
                  <div className={styles.descriptionDiv}>
                    Our customer support team that responds to all your needs
                    related to purchase, shipping and payment
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <div className={styles.titleDiv1}>
                    <img
                      className={styles.iconFilled}
                      alt=""
                      src="/icon--filled11.svg"
                    />
                    <b className={styles.captionDiv}>2</b>
                  </div>
                  <div className={styles.descriptionDiv}>
                    Our consultation team offers customized solutions and
                    consultancies to meet all your needs.
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <div className={styles.titleDiv1}>
                    <img
                      className={styles.iconFilled}
                      alt=""
                      src="/images/icon--filled11.svg"
                    />
                    <b className={styles.captionDiv}>3</b>
                  </div>
                  <div className={styles.descriptionDiv2}>
                    Our competitive prices and fast-track shipping policies
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aboutUsSectionDiv}>
          <img className={styles.patternIcon} alt="" src="/pattern1.svg" />
          <div className={styles.aboutUsFeaturesDiv}>
            <div className={styles.titleDiv}>
              <b className={styles.label}>What we offer:</b>
            </div>
            <div className={styles.grid2x2Div}>
              <div className={styles.row1Div}>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled13.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      Retail medical supply
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled14.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      Wholesale medical supply
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.row1Div}>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled15.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      Consultancy and crisis mitigation solutions
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled14.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>Customer support</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.aboutUsFeaturesDiv1}>
            <div className={styles.titleDiv}>
              <b className={styles.label}>Our main Features:</b>
            </div>
            <div className={styles.grid2x2Div1}>
              <div className={styles.row1Div1}>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled13.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      We ship globally at affordable prices
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled14.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      Our customer care team is always ready to help
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.row1Div1}>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled15.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      We offer quality and affordability
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv3}>
                  <img
                    className={styles.iconFilled3}
                    alt=""
                    src="/icon--filled14.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv4}>
                      We have a wide range of products from top brands
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

export const Head = () => (
  <SEO title="UDS Supply | About Us" description="About" />
);
